body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2{
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 5px;
  margin-left: 1rem;

}

select {
  font-family: Arial, Helvetica, sans-serif;
  width: 200px;
  height: 30px;
  padding: 2px 4px;
  margin: 5px 15px;
  line-height: 20px;
  font-size: 14px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
  align-items: center;
}

li {
  list-style: none;
  line-height: 1.4rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
}

button{
  width: 100px;
  height: 30px;
  margin: 5px 15px;
  line-height: 20px;
  font-size: 14px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
}

button span{
  position: relative;
  z-index: 2;
}

button:hover{
  color: black;
  font-weight: bold;
}

.disable {
  border: 1px solid rgb(174, 0, 0);
  background-color: rgb(238, 0, 0);
  color: white !important;
  cursor: not-allowed;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 5px 10px;
  background-color: #f2f2f2;
  position: relative;
  transition: all .35s;
  margin-top: 10px;
}

.loading {
  margin-top: 10px;
  position: absolute;
  width: 100%;
  height: 90vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
